<!-- Start Page Title Area -->
<section class="page-title-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2>Oprogramowanie dedykowane</h2>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>

</section>
<!-- End Page Title Area -->

<!-- Start About Area -->
<<section class="about-area ptb-100">
    <!--<div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <h2>Kim jesteśmy?</h2>
                        <div class="bar"></div>
                        <p>Jesteśmy firmą podejmującą się trudnych zadań, które wymagają nieszablonowego, innowacyjnego podejścia.</p>
                    </div>
                    <p>Dzisiejsza rzeczywistość, jest na tyle dynamiczna, że trzeba na nią patrzeć z wyprzedzeniem. Obserwujemy uważnie trendy gospodarcze i społeczne, tak też podchodzimy do wyzwań, które stawiają przed nami klienci.</p>
                    <p>Przy każdym projekcie staramy się nie tylko odpowiedzieć na aktualne potrzeby klienta ale również wyprzedzić jego oczekiwania</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <img src="assets/img/onas.png" alt="image">
            </div>

        </div>
    </div>

    <!--<div class="about-inner-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-6">
                    <div class="about-text">
                        <h3>Our Company</h3>
                        <p>Lorem ipsum dolor sit amet, con sectetur adipisicing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="about-text">
                        <h3>Kim jesteśmy?</h3>
                        <p>Jesteśmy grupą doświadczonych osób pracujących w sektorze IT</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="about-text">
                        <h3>What we Value</h3>
                        <p>Lorem ipsum dolor sit amet, con sectetur adipisicing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="about-text">
                        <h3>Contact Us</h3>
                        <p>Lorem ipsum dolor sit amet, con sectetur adipisicing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->

    <!-- Start Team Area -->
    <!--<section class="team-area ptb-100 bg-gray">
        <div class="container">
            <div class="section-title">
                <h2>Nasz zespół</h2>
                <div class="bar"></div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>

            <div class="team-slides owl-carousel owl-theme">
                <div class="single-team-member">
                    <img src="assets/img/team-img1.jpg" alt="team">

                    <div class="team-content">
                        <h3>John Doe</h3>
                        <span>CEO</span>
                        <ul>
                            <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                    </div>
                </div>

                <div class="single-team-member">
                    <img src="assets/img/team-img2.jpg" alt="team">

                    <div class="team-content">
                        <h3>Steven Smith</h3>
                        <span>Developer</span>
                        <ul>
                            <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                    </div>
                </div>

                <div class="single-team-member">
                    <img src="assets/img/team-img3.jpg" alt="team">

                    <div class="team-content">
                        <h3>David Luiz</h3>
                        <span>Designer</span>
                        <ul>
                            <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                    </div>
                </div>

                <div class="single-team-member">
                    <img src="assets/img/team-img4.jpg" alt="team">

                    <div class="team-content">
                        <h3>Park Tim</h3>
                        <span>SEO Expert</span>
                        <ul>
                            <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                    </div>
                </div>

                <div class="single-team-member">
                    <img src="assets/img/team-img5.jpg" alt="team">

                    <div class="team-content">
                        <h3>Doglas Costa</h3>
                        <span>Manager</span>
                        <ul>
                            <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End Team Area -->



    <!-- Start Feedback Area -->
    <!--<section class="feedback-area ptb-100 bg-gray">
        <div class="container">
            <div class="section-title">
                <h2>Testimonials</h2>
                <div class="bar"></div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>

            <div class="feedback-slides owl-carousel owl-theme">
                <div class="single-feedback">
                    <div class="client-info">
                        <img src="assets/img/client1.jpg" alt="client">
                        <h3>Alit John</h3>
                        <span>Web Developer</span>
                    </div>
                    <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual.</p>
                </div>

                <div class="single-feedback">
                    <div class="client-info">
                        <img src="assets/img/client2.jpg" alt="client">
                        <h3>Alit John</h3>
                        <span>Jhon Smith</span>
                    </div>
                    <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual.</p>
                </div>

                <div class="single-feedback">
                    <div class="client-info">
                        <img src="assets/img/client3.jpg" alt="client">
                        <h3>Steven John</h3>
                        <span>Web Developer</span>
                    </div>
                    <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual.</p>
                </div>

                <div class="single-feedback">
                    <div class="client-info">
                        <img src="assets/img/client1.jpg" alt="client">
                        <h3>David Warner</h3>
                        <span>Web Developer</span>
                    </div>
                    <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual.</p>
                </div>
            </div>
        </div>

        <svg class="svg-feedback-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
            <path d="M0,70 C30,130 70,50 100,70 L100,100 0,100 Z" fill="#ffffff"/>
        </svg>
    </section>
    <!-- End Feedback Area -->

    <!--<app-partner></app-partner>

    <!-- Start Platform Connect -->
    <!--<section class="platform-connect ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <h2>Interakcja z użytkownikami na każdej platformie</h2>
                </div>

                <div class="col-lg-3 col-md-6">
                    <a href="#" class="box" target="_blank">
                        <img src="assets/img/mailchimp.png" alt="mailchimp">
                        <h3>Mail Chimp</h3>
                        <span>Wyślij maila</span>
                        <i class="icofont-bubble-right"></i>
                    </a>
                </div>

                <div class="col-lg-3 col-md-6">
                    <a href="#" class="box" target="_blank">
                        <img src="assets/img/slack.png" alt="mailchimp">
                        <h3>Slack</h3>
                        <span>Wiadomości</span>
                        <i class="icofont-bubble-right"></i>
                    </a>
                </div>

                <div class="col-lg-3 col-md-6">
                    <a href="#" class="box" target="_blank">
                        <img src="assets/img/twitter.png" alt="mailchimp">
                        <h3>Twitter</h3>
                        <span>Kanał na Twitterze</span>
                        <i class="icofont-bubble-right"></i>
                    </a>
                </div>

                <div class="col-lg-3 col-md-6">
                    <a href="#" class="box" target="_blank">
                        <img src="assets/img/instagram.png" alt="mailchimp">
                        <h3>Instagram</h3>
                        <span>Kanał na Instagramie</span>
                        <i class="icofont-bubble-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </section>
    <!-- End Platform Connect -->
    <!-- Start CTA Area -->
    <!--<div class="cta-area">
        <div class="container">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-7 col-md-7">
                    <p>Sprawdź naszą oferte!</p>
                </div>

                <div class="col-lg-5 col-md-5 text-end">
                    <a routerLink="/oferta" class="btn btn-primary">Oferta</a>
                </div>
            </div>
        </div>
    </div>
    <!-- End CTA Area -->
</section>
