import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-service-software',
  templateUrl: './service-software.component.html',
  styleUrls: ['./service-software.component.scss']
})
export class ServiceSoftwareComponent implements OnInit {

    constructor( private titleService: Title ) {
        this.titleService.setTitle('Oprogramowanie dedykowane - Everment');
    }

  ngOnInit(): void {
  }

}
