import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";

@Component({
	selector: 'app-startup-agency',
	templateUrl: './startup-agency.component.html',
	styleUrls: ['./startup-agency.component.scss']
})
export class StartupAgencyComponent implements OnInit {

    contactForm: FormGroup;
    sended: boolean
    errorMessage: string;

    constructor(
        private titleService: Title,
        private router: Router,
        private fb: FormBuilder,
        private httpClient: HttpClient
    ) {
        this.contactForm = this.fb.group({
            name: [''],
            email: ['', Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")],
            phone: [''],
            message: ['']
        });
        this.titleService.setTitle('EVERMENT - Sklep internetowy')
    }



    ngOnInit() {

    }

    navigateToFirstSection() {
        window.scrollBy(0, window.innerHeight);
    }

    navigateTo(value: string) {
        this.router.navigateByUrl(value);
    }

    sendMessage() {
        if (this.contactForm.invalid) {
            return;
        }

        this.httpClient.post('https://everment.hidey.eu/api/email/contact', this.contactForm.value).subscribe(data => {
            this.sended = true;
        }, error => {
            this.errorMessage = "Nie możemy dostarczyć wiadomości.. Spróbuj jeszcze raz, jeśli błąd się powtarza, użyj innej formy kontaktu!"
        });

        this.contactForm.reset();
    }
}
