<!-- Start Footer Area -->
<footer class="footer-area ptb-100 pb-0 bg-image">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><img src="assets/img/logo2.png" alt="logo2"></a>
                    <p>Dzięki nowoczesnym technologiom zapewnimy twojej firmie skuteczny rozwój w sieci</p>
                    <ul class="social-list">
                        <li><a href="https://facebook.com/evermentpl" target="_blank"><i class="icofont-facebook"></i></a></li>
                        <li><a href="https://www.instagram.com/everment.official" target="_blank"><i class="icofont-instagram"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/everment" target="_blank"><i class="icofont-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget pl-5">
                    <h3>Firma</h3>

                    <ul class="list">
                        <li><a routerLink="/o-nas">O nas</a></li>
                        <li><a href="https://facebook.com/evermentpl" target="_blank">Media</a></li>
                        <li><a routerLink="/kontakt">Kontakt</a></li>
                        <li><a routerLink="/">Logowanie</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Usługi</h3>

                    <ul class="list">
                        <li><a routerLink="/strona">Strony Internetowe</a></li>
                        <li><a routerLink="/sklep">Sklepy Internetowe</a></li>
                        <li><a routerLink="/hosting">Hosting</a></li>
                        <li><a routerLink="/">Oprogramowanie dedykowane</a></li>
                        <li><a routerLink="/">Socialmedia</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Support</h3>

                    <ul class="list">
                        <li><a routerLink="/o-nas">O nas</a></li>
                        <li><a routerLink="/kontakt">Kontakt</a></li>
                        <li><a routerLink="/faq">FAQ</a></li>
                        <li><a routerLink="/oferta">Oferta</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copyright-area">
        <div class="container">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>Copyright © 2021 <b>EVERMENT</b>. Wszelkie prawa zastrzeżone.</p>
                </div>

                <div class="col-lg-6 col-md-6">

                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer Area -->

<!-- Back Top top -->
<div class="back-to-top" (click)="navigateToTop()">Góra</div>
<!-- End Back Top top -->
