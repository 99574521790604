<!-- Start Page Title Area -->
<section class="page-title-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2>Nasze usługi</h2>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>

</section>
<!-- End Page Title Area -->

<!-- Start Services Area -->
<section class="services-area ptb-50">
    <div class="container">
            <div class="section-title">
                <h2>Kliknij i sprawdź</h2>
                <div class="bar"></div>
            </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services clickable" (click)="navigateTo('strona')">
                    <div class="icon">
                        <i class="icofont-web"></i>
                    </div>
                    <h3>Strony internetowe</h3>
                    <p>Wykonanie strony internetowej</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services clickable" (click)="navigateTo('sklep')">
                    <div class="icon">
                        <i class="icofont-shopping-cart"></i>
                    </div>
                    <h3>Sklepy internetowe</h3>
                    <p>Wykonanie sklepu internetowego</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services clickable" (click)="navigateTo('hosting')">
                    <div class="icon">
                        <i class="icofont-share"></i>
                    </div>
                    <h3>Hosting</h3>
                    <p>Utrzymanie strony / sklepu / aplikacji</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-code"></i>
                    </div>
                    <h3>Oprogramowanie dedykowane</h3>
                    <p>Już wkrótce więcej informacji...</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-deviantart"></i>
                    </div>
                    <h3>Consulting IT</h3>
                    <p>Już wkrótce więcej informacji...</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-facebook"></i>
                    </div>
                    <h3>SocialMedia</h3>
                    <p>Już wkrótce więcej informacji...</p>
                </div>
            </div>
        </div>>
    </div>


</section>
<!-- End Services Area -->
