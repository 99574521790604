import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services1',
  templateUrl: './services1.component.html',
  styleUrls: ['./services1.component.scss']
})
export class Services1Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
