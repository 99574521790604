import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {Router} from "@angular/router";

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

    constructor(
        private titleService: Title,
        private router: Router
    ) {
        this.titleService.setTitle('EVERMENT - Oferta');
    }

    ngOnInit() {
    }

    navigateTo(value: string) {
        this.router.navigateByUrl(value);
    }
}
