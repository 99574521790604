import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-service-socialmedia',
  templateUrl: './service-socialmedia.component.html',
  styleUrls: ['./service-socialmedia.component.scss']
})
export class ServiceSocialmediaComponent implements OnInit {

    constructor( private titleService: Title ) {
        this.titleService.setTitle('Socialmedia - Everment');
    }

  ngOnInit(): void {
  }

}
