<!-- Start Startup Main Banner -->
<div class="startup-home">
    <div class="main-banner startup-bg1">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <div class="hero-content">
                                <h1>Wprowadź z nami swój sklep na wyższy poziom</h1>
                                <p>Wykoszystując nowoczesne technologie zaoferujemy Ci dedykowany sklep dzięki której twoja sprzedaż będzie dużo efektywniejsza</p>
                                <a class="btn btn-primary" (click)="navigateToFirstSection()">SPRAWDŹ CO OFERUJEMY</a>
                                <!--<a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"> Zobacz film <i class="icofont-play-alt-3"></i></a>-->
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="startup-image">
                                <img src="assets/img/startup-shape/board.png" class="wow fadeInDown" data-wow-delay="0.6s" alt="board">
                                <img src="assets/img/startup-shape/chart1.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="chart1">
                                <img src="assets/img/startup-shape/chart2.png" class="wow fadeInLeft" data-wow-delay="0.6s" alt="chart2">
                                <img src="assets/img/startup-shape/check-mark.png" class="wow zoomIn" data-wow-delay="0.6s" alt="check-mark">
                                <img src="assets/img/startup-shape/girl1.png" class="wow bounceIn" data-wow-delay="0.6s" alt="girl1">
                                <img src="assets/img/startup-shape/girl2.png" class="wow fadeInDown" data-wow-delay="0.6s" alt="girl2">
                                <img src="assets/img/startup-shape/line.png" class="wow zoomIn" data-wow-delay="0.6s" alt="line">
                                <img src="assets/img/startup-shape/man.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="man">
                                <img src="assets/img/startup-shape/progress.png" class="wow rotateIn" data-wow-delay="0.6s" alt="progress">
                                <img src="assets/img/startup-shape/table.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="table">
                                <img src="assets/img/startup-shape/main-image.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="main-image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-gray shape-1"></div>
        <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
        <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
        <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
        <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
        <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
        <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
    </div>
</div>
<!-- End Startup Main Banner -->

<!--
<app-partner></app-partner>
-->
<!-- Start SAAS Tools Area -->
<section class="saas-tools ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Czym wyróżni się twój sklep?</h2>
            <div class="bar"></div>
            <p>Przejrzystość, łatwość użytkowania, rezponsywność...</p>
        </div>

        <div class="tab">
            <ul class="tabs">
                <li><a href="#">
                    <i class="icofont-contrast"></i>
                    <br>
                    Wygląd
                </a></li>

                <li><a href="#">
                    <i class="icofont-credit-card"></i>
                    <br>
                    Płatności
                </a></li>

                <li><a href="#">
                    <i class="icofont-chart-histogram"></i>
                    <br>
                    Pozycjonowanie
                </a></li>


                <li><a href="#">
                    <i class="icofont-dashboard-web"></i>
                    <br>
                    Intuicyjny panel
                </a></li>

                <li><a href="#">
                    <i class="icofont-wrench"></i>
                    <br>
                    Wsparcie techniczne
                </a></li>
            </ul>

            <div class="tab_content">
                <div class="tabs_item">
                    <div class="row h-100 justify-content-center align-items-center">
                        <div class="col-lg-6 col-md-6">
                            <div class="tabs_item_content mt-0">
                                <h3>Wygląd</h3>
                                <p>Wygląd to jeden z najważniejszych aspektów sklepu internetowego. Staramy się aby nasze projekty powodowały, że klienci będą chętnie wracać po oferowane produkty</p>
                                <ul>
                                    <li><i class="icofont-ui-check"></i> Nowoczesny Design</li>
                                    <li><i class="icofont-ui-check"></i> Responsywny wygląd dostępny na wszystkich urządzeniach</li>
                                    <li><i class="icofont-ui-check"></i> Intuicyjny system zakupów</li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="tabs_item_img">
                                <img src="assets/img/features-img-appearance.png" alt="img">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row h-100 justify-content-center align-items-center">
                        <div class="col-lg-6 col-md-6">
                            <div class="tabs_item_img">
                                <img src="assets/img/features-img-pay.png" alt="img">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="tabs_item_content mb-0">
                                <h3>Płatności</h3>
                                <p>Oferujemy integrację płatności ze wskazanym dostawcą. </p>
                                <ul>
                                    <li><i class="icofont-ui-check"></i> Szybkie</li>
                                    <li><i class="icofont-ui-check"></i> Bezpieczne</li>
                                    <li><i class="icofont-ui-check"></i> Latwe w obsłudze</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row h-100 justify-content-center align-items-center">
                        <div class="col-lg-6 col-md-6">
                            <div class="tabs_item_content mt-0">
                                <h3>Pozycjonowanie</h3>
                                <p>Poprawne ustawienia strony są niezbędne aby sklep internetowy był dobrze pozycjonowany w dostępnych na rynku przeglądarkach</p>
                                <ul>
                                    <li><i class="icofont-ui-check"></i> Bezpieczne metody</li>
                                    <li><i class="icofont-ui-check"></i> Wysokie, stabilne pozycje</li>
                                    <li><i class="icofont-ui-check"></i> Bieżąca analiza potrzeb</li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="tabs_item_img">
                                <img src="assets/img/features-img-ads.png" alt="img">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row h-100 justify-content-center align-items-center">
                        <div class="col-lg-6 col-md-6">
                            <div class="tabs_item_img">
                                <img src="assets/img/features-img1.png" alt="img">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="tabs_item_content mb-0">
                                <h3>Intuicyjny Panel</h3>
                                <p>Latwa obsługa i niezależnośc - właśnie takie narzędzie do obsługi sklepu oferujemy naszym klientom.</p>
                                <ul>
                                    <p>Latwa edycja:</p>
                                    <li><i class="icofont-ui-check"></i> Ofert</li>
                                    <li><i class="icofont-ui-check"></i> Stron</li>
                                    <li><i class="icofont-ui-check"></i> Użytkowników</li>
                                    <li><i class="icofont-ui-check"></i> Wyglądu</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row h-100 justify-content-center align-items-center">
                        <div class="col-lg-6 col-md-6">
                            <div class="tabs_item_content mt-0">
                                <h3>Wsparcie techniczne</h3>
                                <p>Wraz z podjęciem z nami współpracy oferujemy wsparcie techniczne i pomoc w utrzymaniu sklepu</p>
                                <ul>
                                    <li><i class="icofont-ui-check"></i> Pomoc w utrzymaniu sklepu</li>
                                    <li><i class="icofont-ui-check"></i> Video poradniki</li>
                                    <li><i class="icofont-ui-check"></i> Stały kontakt z konsultantem</li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="tabs_item_img">
                                <img src="assets/img/features-img-help.png" alt="img">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End SAAS Tools Area -->
<!-- Start Features Area -->
<section class="features-area startup-features ptb-100">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-holder-box">
                            <img src="assets/img/payment-img1.png" alt="image">
                            <h3>Szybkość działania</h3>
                            <p>Każdy sklep jest optymalizowany pod kątem szybkości działania</p>
                        </div>

                        <div class="single-holder-box mt-30">
                            <img src="assets/img/payment-img2.png" alt="image">
                            <h3>Analiza konkurencji</h3>
                            <p>Wdrażanie nowości i ciągła aktualizacja oprogramowania względem potrzeb konsumenta to nieodłączna część naszej pracy.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-holder-box mt-50">
                            <img src="assets/img/payment-img3.png" alt="image">
                            <h3>Płatności</h3>
                            <p>Oferujemy wdrożenie najnowszych metod płatności dostępnych na rynku.</p>
                        </div>

                        <div class="single-holder-box mt-30">
                            <img src="assets/img/payment-img4.png" alt="image">
                            <h3>Responsywność</h3>
                            <p>Niezależnie czy będzie to strona, sklep czy inna aplikacja. Wszystko co robimy będzie dostępne na każdym urządzeniu</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="features-holder-content">
                    <div class="section-title">
                        <h2>Stworzymy dla Ciebie sklep dostosowany do twoich potrzeb!</h2>
                        <div class="bar"></div>
                        <p>Każdy sklep który wydajemy na światło dziennie jest personalizowany specjalnie pod potrzebu naszego klienta. Nie ważne czy masz butik, sklep motoryzacyjny czy prowadzisz inną sprzedaż.. Nasz sklep będzie wykonany i dostosowany dla Ciebie</p>
                    </div>
                    <p></p>
                    <a routerLink="/kontakt" class="btn btn-primary">Kontakt</a>
                </div>
            </div>
        </div>
    </div>

    <!--<div class="features-inner-area">
        <div class="container">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="features-inner-content">
                        <div class="features-item">
                            <div class="icon">
                                <i class="icofont-cloud-refresh"></i>
                            </div>
                            <h3>Cloud Service</h3>
                            <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                        </div>

                        <div class="features-item">
                            <div class="icon">
                                <i class="icofont-gift"></i>
                            </div>
                            <h3>Daily Free Gift</h3>
                            <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                        </div>

                        <div class="features-item">
                            <div class="icon">
                                <i class="icofont-live-support"></i>
                            </div>
                            <h3>24/7 Support</h3>
                            <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="features-image">
                        <img src="assets/img/features-img1.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape7"><img src="assets/img/shape7.png" alt="shape"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
    <div class="shape10"><img src="assets/img/shape10.svg" alt="shape"></div>
    <div class="shape11 rotateme"><img src="assets/img/shape11.svg" alt="shape"></div>
</section>
<!-- End Features Area -->

<!-- Start Overview Area -->
<!--<section class="overview-section ptb-100">
    <div class="container">
        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/marketing-3.png" alt="image">
                </div>

                <div class="col-lg-6 col-md-6 overview-content">
                    <div class="icon">
                        <i class="icofont-power"></i>
                    </div>
                    <h3>Get Started With Our Software</h3>
                    <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                    <ul>
                        <li>Unimited Video Call</li>
                        <li>Add Favourite contact</li>
                        <li>Camera Filter</li>
                    </ul>
                    <a routerLink="/" class="btn btn-primary">Read More</a>
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-content">
                    <div class="icon">
                        <i class="icofont-share-alt"></i>
                    </div>
                    <h3>Solve Your Problem With Very Faster</h3>
                    <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                    <ul>
                        <li>Unimited Video Call</li>
                        <li>Add Favourite contact</li>
                        <li>Camera Filter</li>
                    </ul>
                    <a routerLink="/" class="btn btn-primary">Read More</a>
                </div>

                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/marketing-2.png" alt="image">
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/marketing-1.png" alt="image">
                </div>

                <div class="col-lg-6 col-md-6 overview-content">
                    <div class="icon">
                        <i class="icofont-ui-messaging"></i>
                    </div>
                    <h3>Engineered And Optimization By Conveying. </h3>
                    <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                    <ul>
                        <li>Unimited Video Call</li>
                        <li>Add Favourite contact</li>
                        <li>Camera Filter</li>
                    </ul>
                    <a routerLink="/" class="btn btn-primary">Read More</a>
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-content">
                    <div class="icon">
                        <i class="icofont-users-social"></i>
                    </div>
                    <h3>Quick & Easy Process</h3>
                    <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                    <ul>
                        <li>Unimited Video Call</li>
                        <li>Add Favourite contact</li>
                        <li>Camera Filter</li>
                    </ul>
                    <a routerLink="/" class="btn btn-primary">Read More</a>
                </div>

                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/overview-4.png" alt="image">
                </div>
            </div>
        </div>
    </div>

    <div class="shape7"><img src="assets/img/shape7.png" alt="shape"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
    <div class="shape10"><img src="assets/img/shape10.svg" alt="shape"></div>
    <div class="shape11 rotateme"><img src="assets/img/shape11.svg" alt="shape"></div>
</section>
<!-- End Overview Area -->

<!-- Start App Funfacts Area -->
<!--<section class="app-funfacts-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-funfact-box">
                    <div class="icon">
                        <i class="icofont-users-alt-5"></i>
                    </div>
                    <h3><span class="count">2150</span>+</h3>
                    <p>Active Clients</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-funfact-box">
                    <div class="icon">
                        <i class="icofont-rocket-alt-1"></i>
                    </div>
                    <h3><span class="count">86</span>%</h3>
                    <p>Success Rate</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-funfact-box">
                    <div class="icon">
                        <i class="icofont-laptop-alt"></i>
                    </div>
                    <h3><span class="count">550</span>+</h3>
                    <p>Projects Done</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-funfact-box">
                    <div class="icon">
                        <i class="icofont-win-trophy"></i>
                    </div>
                    <h3><span class="count">35</span>+</h3>
                    <p>Awards</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End App Funfacts Area -->


<!-- Start Project Area -->
<!--<section class="project-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Recent Work</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
    </div>

    <div class="project-slides owl-carousel owl-theme">
        <div class="single-project">
            <div class="project-image">
                <img src="assets/img/work-img1.jpg" alt="work">
                <a href="assets/img/work-img1.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>

            <div class="project-content">
                <span>Digital</span>
                <h3><a routerLink="/">Network Marketing</a></h3>
            </div>
        </div>

        <div class="single-project">
            <div class="project-image">
                <img src="assets/img/work-img2.jpg" alt="work">
                <a href="assets/img/work-img2.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>

            <div class="project-content">
                <span>Digital</span>
                <h3><a routerLink="/">Network Marketing</a></h3>
            </div>
        </div>

        <div class="single-project">
            <div class="project-image">
                <img src="assets/img/work-img3.jpg" alt="work">
                <a href="assets/img/work-img3.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>

            <div class="project-content">
                <span>Digital</span>
                <h3><a routerLink="/">Network Marketing</a></h3>
            </div>
        </div>

        <div class="single-project">
            <div class="project-image">
                <img src="assets/img/work-img4.jpg" alt="work">
                <a href="assets/img/work-img4.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>

            <div class="project-content">
                <span>Digital</span>
                <h3><a routerLink="/">Network Marketing</a></h3>
            </div>
        </div>

        <div class="single-project">
            <div class="project-image">
                <img src="assets/img/work-img5.jpg" alt="work">
                <a href="assets/img/work-img5.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>

            <div class="project-content">
                <span>Digital</span>
                <h3><a routerLink="/">Network Marketing</a></h3>
            </div>
        </div>

        <div class="single-project">
            <div class="project-image">
                <img src="assets/img/work-img6.jpg" alt="work">
                <a href="assets/img/work-img6.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
            </div>

            <div class="project-content">
                <span>Digital</span>
                <h3><a routerLink="/">Network Marketing</a></h3>
            </div>
        </div>
    </div>
</section>-->
<!-- End Project Area -->

<!-- Start Team Area -->
<!--<section class="team-area ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Our Smart Team</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="team-slides owl-carousel owl-theme">
            <div class="single-team-member-box">
                <img src="assets/img/team-img1.jpg" alt="team">

                <div class="team-content">
                    <h3>John Doe</h3>
                    <span>CEO</span>
                    <ul>
                        <li><a href="#" class="facebook" target="_blank"><i class="icofont-facebook"></i></a></li>
                        <li><a href="#" class="instagram" target="_blank"><i class="icofont-instagram"></i></a></li>
                        <li><a href="#" class="twitter" target="_blank"><i class="icofont-twitter"></i></a></li>
                        <li><a href="#" class="linkedin" target="_blank"><i class="icofont-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="single-team-member-box">
                <img src="assets/img/team-img2.jpg" alt="team">

                <div class="team-content">
                    <h3>Steven Smith</h3>
                    <span>Developer</span>
                    <ul>
                        <li><a href="#" class="facebook" target="_blank"><i class="icofont-facebook"></i></a></li>
                        <li><a href="#" class="instagram" target="_blank"><i class="icofont-instagram"></i></a></li>
                        <li><a href="#" class="twitter" target="_blank"><i class="icofont-twitter"></i></a></li>
                        <li><a href="#" class="linkedin" target="_blank"><i class="icofont-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="single-team-member-box">
                <img src="assets/img/team-img3.jpg" alt="team">

                <div class="team-content">
                    <h3>David Luiz</h3>
                    <span>Designer</span>
                    <ul>
                        <li><a href="#" class="facebook" target="_blank"><i class="icofont-facebook"></i></a></li>
                        <li><a href="#" class="instagram" target="_blank"><i class="icofont-instagram"></i></a></li>
                        <li><a href="#" class="twitter" target="_blank"><i class="icofont-twitter"></i></a></li>
                        <li><a href="#" class="linkedin" target="_blank"><i class="icofont-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="single-team-member-box">
                <img src="assets/img/team-img4.jpg" alt="team">

                <div class="team-content">
                    <h3>Park Tim</h3>
                    <span>SEO Expert</span>
                    <ul>
                        <li><a href="#" class="facebook" target="_blank"><i class="icofont-facebook"></i></a></li>
                        <li><a href="#" class="instagram" target="_blank"><i class="icofont-instagram"></i></a></li>
                        <li><a href="#" class="twitter" target="_blank"><i class="icofont-twitter"></i></a></li>
                        <li><a href="#" class="linkedin" target="_blank"><i class="icofont-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="single-team-member-box">
                <img src="assets/img/team-img5.jpg" alt="team">

                <div class="team-content">
                    <h3>Doglas Costa</h3>
                    <span>Manager</span>
                    <ul>
                        <li><a href="#" class="facebook" target="_blank"><i class="icofont-facebook"></i></a></li>
                        <li><a href="#" class="instagram" target="_blank"><i class="icofont-instagram"></i></a></li>
                        <li><a href="#" class="twitter" target="_blank"><i class="icofont-twitter"></i></a></li>
                        <li><a href="#" class="linkedin" target="_blank"><i class="icofont-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>-->
<!-- End Team Area -->

<!-- Start Pricing Area -->
<!--<section class="pricing-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Easy Pricing Plans</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="pricing-table-box">
                    <div class="pricingTable-header">
                        <h3 class="title">Basic</h3>
                        <div class="price-value"><sup>$</sup>59.99 <span>/per year</span></div>
                    </div>

                    <ul class="pricing-content">
                        <li>Managment & Marketing</li>
                        <li>SEO Optimization</li>
                        <li>25 Analytics Campaign</li>
                        <li>1,300 Change Keywords</li>
                        <li>25 Social Media Reviews</li>
                        <li>1 Free Optimization</li>
                        <li>24/7 Support</li>
                    </ul>

                    <a routerLink="/" class="btn btn-primary">Buy Now</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="pricing-table-box">
                    <div class="pricingTable-header">
                        <h3 class="title">Ultra</h3>
                        <div class="price-value"><sup>$</sup>79.99 <span>/per year</span></div>
                    </div>

                    <ul class="pricing-content">
                        <li>Managment & Marketing</li>
                        <li>SEO Optimization</li>
                        <li>25 Analytics Campaign</li>
                        <li>1,300 Change Keywords</li>
                        <li>25 Social Media Reviews</li>
                        <li>1 Free Optimization</li>
                        <li>24/7 Support</li>
                    </ul>

                    <a routerLink="/" class="btn btn-primary">Buy Now</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="pricing-table-box">
                    <div class="pricingTable-header">
                        <h3 class="title">Pro</h3>
                        <div class="price-value"><sup>$</sup>99.99 <span>/per year</span></div>
                    </div>

                    <ul class="pricing-content">
                        <li>Managment & Marketing</li>
                        <li>SEO Optimization</li>
                        <li>25 Analytics Campaign</li>
                        <li>1,300 Change Keywords</li>
                        <li>25 Social Media Reviews</li>
                        <li>1 Free Optimization</li>
                        <li>24/7 Support</li>
                    </ul>

                    <a routerLink="/" class="btn btn-primary">Buy Now</a>
                </div>
            </div>
        </div>
    </div>
</section>-->
<!-- End Pricing Area -->

<!-- Start App Download Area -->
<!--<section class="app-download ptb-100 bg-gray">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6">
                <div class="section-title">
                    <h2>Download Our Apps Today</h2>
                    <div class="bar"></div>
                    <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account the system and expound the actual teachings of the great explore.</p>
                    <div class="download-btn">
                        <a href="#" target="_blank"><i class="icofont-brand-android-robot"></i> Available On <span>Google Store</span></a>
                        <a href="#" target="_blank"><i class="icofont-brand-apple"></i> Available On <span>Apple Store</span></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="download-image">
                    <img src="assets/img/app-img1.png" alt="image">
                    <img src="assets/img/app-img2.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>-->
<!-- End App Download Area -->

<!-- Start Blog Area -->
<!--<section class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Latest News</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="blog-slides owl-carousel owl-theme">
            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img1.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 23, 2019</li>
                    </ul>
                    <h3><a routerLink="/blog-details">The Most Popular New Business Apps</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img2.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 16, 2019</li>
                    </ul>
                    <h3><a routerLink="/blog-details">The Best Marketing Management Tools</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img3.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 14, 2019</li>
                    </ul>
                    <h3><a routerLink="/blog-details">3 WooCommerce Plugins to Boost Sales</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img4.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 06, 2019</li>
                    </ul>
                    <h3><a routerLink="/blog-details">CakeMail Review – Design Custom Emails</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img5.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 04, 2019</li>
                    </ul>
                    <h3><a routerLink="/blog-details">The Most Popular New Apps in 2019</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img3.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 26, 2019</li>
                    </ul>
                    <h3><a routerLink="/blog-details">The Fastest Growing Apps in 2019</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>-->
<!-- End Blog Area -->

<!-- Start CTA Area -->
<div class="cta-area">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-7">
                <p>Sprawdź nasze inne usługi!</p>
            </div>

            <div class="col-lg-5 text-end">
                <a routerLink="/oferta" class="btn btn-primary">Oferta</a>
            </div>
        </div>
    </div>
</div>
<!-- End CTA Area -->

<!-- Start Feedback Area -->
<!--<section class="feedback-area ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Testimonials</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="feedback-slides owl-carousel owl-theme">
            <div class="single-feedback-box">
                <div class="client-info">
                    <img src="assets/img/client1.jpg" alt="client">
                    <h3>Alit John</h3>
                    <span>Web Developer</span>
                </div>
                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual. Plan ahead by day, week, or month, and see project status at a glance.</p>
            </div>

            <div class="single-feedback-box">
                <div class="client-info">
                    <img src="assets/img/client2.jpg" alt="client">
                    <h3>Alit John</h3>
                    <span>SEO Expert</span>
                </div>
                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual. Plan ahead by day, week, or month, and see project status at a glance.</p>
            </div>

            <div class="single-feedback-box">
                <div class="client-info">
                    <img src="assets/img/client3.jpg" alt="client">
                    <h3>Steven John</h3>
                    <span>Web Developer</span>
                </div>
                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual. Plan ahead by day, week, or month, and see project status at a glance.</p>
            </div>

            <div class="single-feedback-box">
                <div class="client-info">
                    <img src="assets/img/client4.jpg" alt="client">
                    <h3>David Warner</h3>
                    <span>Web Developer</span>
                </div>
                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual. Plan ahead by day, week, or month, and see project status at a glance.</p>
            </div>
        </div>
    </div>

    <svg class="svg-feedback-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
        <path d="M0,70 C30,130 70,50 100,70 L100,100 0,100 Z" fill="#ffffff"/>
    </svg>
</section>
<!-- End Feedback Area -->

<!-- Start Get Started Area -->
<section class="get-started ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Skontaktuj się z nami</h2>
            <div class="bar"></div>
            <p>I dowiedz się więcej o naszej ofercie!</p>
        </div>

        <div class="get-started-form">
            <form [formGroup]="contactForm">
                <div class="form-group">
                    <input type="text" class="form-control" formControlName="name" placeholder="Imię">
                </div>

                <div class="form-group">
                    <input type="email" class="form-control" formControlName="email" placeholder="Email">
                    <p class="error-message" *ngIf="contactForm.get('email').touched && contactForm.get('email').invalid">Wprowadzony e-mail jest niepoprawny!</p>
                </div>

                <div class="form-group">
                    <input type="text" class="form-control" formControlName="phone" placeholder="Telefon">
                </div>

                <div class="form-group">
                    <textarea type="text" class="form-control" formControlName="phone" cols="30" rows="6" placeholder="Wiadomość"></textarea>
                </div>

                <p class="error-message" *ngIf="errorMessage">{{errorMessage}}</p>
                <p style="color: green" *ngIf="sended">Wiadomość została wysłana, wkrótce się skontaktujemy!</p>

                <button type="submit" (click)="sendMessage()" class="btn btn-primary">Wyślij</button>
            </form>
        </div>
    </div>

    <canvas id="canvas"></canvas>
</section>
<!-- End Get Started Area -->

<!-- Start Platform Connect -->
<!--<section class="platform-connect ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <h2>Interact With Your Users On Every Single Platform</h2>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box" target="_blank">
                    <img src="assets/img/mailchimp.png" alt="mailchimp">
                    <h3>Mail Chimp</h3>
                    <span>Send Mail</span>
                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box" target="_blank">
                    <img src="assets/img/slack.png" alt="mailchimp">
                    <h3>Slack</h3>
                    <span>Messaging</span>
                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box" target="_blank">
                    <img src="assets/img/twitter.png" alt="mailchimp">
                    <h3>Twitter</h3>
                    <span>Twitter Feed</span>
                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box" target="_blank">
                    <img src="assets/img/instagram.png" alt="mailchimp">
                    <h3>Instagram</h3>
                    <span>News Feed</span>
                    <i class="icofont-bubble-right"></i>
                </a>
            </div>
        </div>
    </div>
</section>
<!-- End Platform Connect -->
