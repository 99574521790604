<!-- Start Page Title Area -->
<section class="page-title-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2>Kontakt</h2>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>

</section>
<!-- End Page Title Area -->

<!-- Start Contact Info Area -->
<section class="contact-info-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="icofont-email"></i>
                    </div>
                    <h3>Napisz do nas</h3>
                    <p><a href="mailto:admin@kilian.com">kontakt@everment.pl</a></p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="icofont-facebook"></i>
                    </div>
                    <h3>Odwiedź nas na facebooku</h3>
                    <p>facebook.com/evermentpl</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="icofont-phone"></i>
                    </div>
                    <h3>Zadzwoń do nas</h3>
                    <p><a href="tel:+123456789">+48 732 080 987</a></p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Info Area -->

<!-- Start Contact Area -->
<section class="contact-area ptb-100 pt-0">
    <div class="container">
        <div class="section-title">
            <h2>Skontaktuj sie z nami</h2>
            <div class="bar"></div>
            <p>Skorzystaj z formularza w celu skontaktowania się z nami!</p>
        </div>

        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <img src="assets/img/kontakt.png" alt="image">
            </div>

            <div class="col-lg-6 col-md-12">
                <form id="contactForm" [formGroup]="contactForm">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <input type="text" class="form-control" formControlName="name"  required placeholder="Imię">
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <input type="email" class="form-control" formControlName="email" required placeholder="Email">
                                <p class="error-message" *ngIf="contactForm.get('email').touched && contactForm.get('email').invalid">Wprowadzony e-mail jest niepoprawny!</p>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-6">
                            <div class="form-group">
                                <input type="text" class="form-control" formControlName="phone" placeholder="Telefon">
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <textarea name="message" class="form-control" formControlName="message" id="message" cols="30" rows="5" placeholder="Twoja wiadomość"></textarea>
                            </div>
                        </div>

                        <p class="error-message" *ngIf="errorMessage">{{errorMessage}}</p>
                        <p style="color: green" *ngIf="sended">Wiadomość została wysłana, wkrótce się skontaktujemy!</p>

                        <div class="col-lg-12 col-md-12">
                            <button type="button" (click)="sendMessage()" class="btn btn-primary">Wyślij wiadomość</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Area -->
