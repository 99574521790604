<!-- Start Sidebar Modal -->
<div class="sidebar-modal">
    <div class="modal right fade" id="myModal2" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i class="icofont-close"></i></span></button>
                    <h2 class="modal-title" id="myModalLabel2"><a routerLink="/"><img src="assets/img/logo.png" alt="logo"></a></h2>
                </div>

                <div class="modal-body">
                    <div class="sidebar-modal-widget">
                        <h3 class="title">Przydatne linki</h3>

                        <ul>
                            <li><a routerLink="/faq">FAQ</a></li>
                        </ul>
                    </div>

                    <div class="sidebar-modal-widget">
                        <h3 class="title">Informacje kontaktowe</h3>

                        <ul class="contact-info">
                            <li>
                                <i class="icofont-facebook"></i>
                                Facebook
                                <span>facebook.com/evermentpl</span>
                            </li>
                            <li>
                                <i class="icofont-email"></i>
                                Email
                                <span>kontakt@everment.pl</span>
                            </li>
                            <li>
                                <i class="icofont-phone"></i>
                                Telefon
                                <span>+48 732 080 987</span>
                            </li>
                        </ul>
                    </div>

                    <div class="sidebar-modal-widget">
                        <h3 class="title">Odwiedź nas</h3>

                        <ul class="social-list">
                            <li><a href="https://facebook.com/evermentpl" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/everment.official/" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="https://www.linkedin.com/company/everment" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Sidebar Modal -->
